.caption-absolute {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
    color: white;
    a {
        color: white!important;
    }
}
.caption, .caption p {
    padding: 3px;
    font-size: 0.9rem!important;
    color: inherit;
    a {
        color: #999;
        text-decoration: underline;
    }
    * {
        padding: 0;
        margin: 0;
    }
}

.entryList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
        flex: 1 1 50%;
    }
    .icon-text-simple {
        p, h3 {
            margin-left: 20px;
        }
    }
}
@media only screen and (max-width: 480px) {
    .entryList {
        > div {
            flex: 1 1 100%;
        }
    }
}

.contact-map iframe {
    width: 100%;
}

figure {
    margin: 0 0 1em 0;
}

figcaption {
    font-size: 0.9rem;
}

.modDefaultSlider .sequence .title h2 {
    text-shadow: 0px 0px 1px rgba(0,0,0,0.3); /* add a small border to text on the homepage hero to make it easier to find images */
}

.linkBlock {
    position: relative;
    .blockLink {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;   
    }
}

a.inherit {
    color: inherit;
}
a.inherit:hover {
    color: #F9CE06;
}

:target::before {
    content: "";
    display: block;
    margin-top: -80px;
    height: 80px;
    width: 1px;
  }

